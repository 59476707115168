import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";

import { config } from "_config";
import { CARD_DECLINED_ALLOWED_PRODUCTS, CUSTOM_ACTION_CODES, MAIN_CATEGORY, PRODUCT_CODES } from "_constants/products";
import { EIN_title_array, ERROR_ACTIONS_OBJ } from "_constants/variables";
import { findProduct, getProductsFiles } from "_helpers";
import { reverse } from "_helpers/array";
import { getAggregatedProcessingStatus } from "_helpers/orderDetails";
import { createTrademarkDetailsData, parseTrademarkRegistrationProduct } from "_helpers/trademark";
import { selectSubscriptions } from "_store/billing/selector";

export const selectLoading = (state) => Boolean(state.orders.loading);
export const selectActiveOrderLoading = (state) => state.orders.isActiveOrderLoaded;
export const selectOrdersLoading = (state) => state.orders.isOrdersLoaded;
export const selectError = (state) => state.orders.error;
export const selectList = (state) => {
  if (!Array.isArray(state.orders.list)) return [];
  return state.orders.list;
};
export const selectFileTypes = (state) => state.orders.fileTypes;
export const selectActiveOrderId = (state) => state.orders.activeOrderId;
export const selectActiveOrderDetails = (state) => state.orders.activeOrderDetails;
// export const selectActiveOrderNotes = (state) => state.orders.activeOrderDetails?.notes;
export const selectFileLoading = (state) => state.orders.fileLoading;
export const selectProcessingStatuses = (state) => state.orders.processingStatuses;
export const selectProcessingErrors = (state) => state.orders.processingErrors;
export const selectStates = (state) => state.orders.states;
// export const selectActiveOrderProductCodes = (state) => {
//   return state.orders.activeOrderDetails?.productCodes?.map(({ code }) => code) || [];
// };

export const selectRaAddresses = (state) => state.orders.raAddress.data;
export const selectRaAddressesError = (state) => state.orders.raAddress.error;
export const selectRaAddressesLoading = (state) => state.orders.raAddress.loading;

export const selectActiveOrderState = createSelector([selectActiveOrderDetails], (activeOrderDetails) => {
  console.log({ activeOrderDetails });
  return activeOrderDetails?.products?.reduce((res, product) => {
    if (!res) {
      res = product?.organizedState || product?.state || "";
    }

    return res;
  }, "");
});

export const selectActiveOrderNotes = createSelector([selectActiveOrderDetails], (activeOrderDetails) => {
  if (!activeOrderDetails || !activeOrderDetails?.notes?.length) return [];

  return activeOrderDetails.notes;
});

export const selectActiveOrderProductCodes = createSelector([selectActiveOrderDetails], (activeOrderDetails) => {
  if (!activeOrderDetails || !activeOrderDetails?.productCodes?.length) return [];

  return activeOrderDetails.productCodes;
});

export const selectActiveOrderUpsells = createSelector([selectList, selectActiveOrderId], (list, orderId) => {
  if (list.length === 0 || !orderId) return [];

  return list?.filter((order) => orderId === order.parentOrderUid);
});

export const selectActiveOrderRelatedCodes = createSelector(
  [selectActiveOrderProductCodes, selectActiveOrderUpsells],
  (codes, list) => {
    if (!list?.length && !codes?.length) return [];

    const resultArray = [...codes];
    list.forEach((upsell) => {
      upsell.productCodes.forEach((item) => {
        if (!resultArray.includes(item.code)) resultArray.push(item.code);
      });
    });

    return resultArray;
  }
);

export const selectCompanyOptions = createSelector([selectList], (list) => {
  const filteredList = list?.filter(({ products }) => {
    return products?.find(
      ({ code: { code, main } }) =>
        [PRODUCT_CODES.incStatesLLCIncorporation, PRODUCT_CODES.incStatesLLCIncorporationV2].includes(code) && main
    );
  });

  const mappedList = filteredList?.map(({ uid, crmOrderId, products }) => {
    const { companyName } = products?.find(({ code: { category, main } }) => category === MAIN_CATEGORY && main) || {};
    return { label: `${crmOrderId} — ${companyName}`, value: uid };
  });

  return reverse(mappedList);
});

export const selectCompanyOptionByOrderId = (orderId) =>
  createSelector([selectCompanyOptions], (companyOptions) => companyOptions?.find(({ value }) => value === orderId));

export const selectCrmOrderId = createSelector(
  [selectActiveOrderDetails],
  (activeOrderDetails) => activeOrderDetails?.crmOrderId
);

export const selectOrderDate = createSelector(
  [selectActiveOrderDetails],
  (activeOrderDetails) => activeOrderDetails?.orderDate
);

export const selectMainProduct = createSelector([selectActiveOrderDetails], (activeOrderDetails) =>
  activeOrderDetails?.products?.find(({ code: { category, main } }) => category === MAIN_CATEGORY && main)
);

export const selectTrademarkProduct = createSelector(
  [selectActiveOrderDetails, selectActiveOrderUpsells],
  (activeOrderDetails, activeOrderUpsells) => {
    const registerTrademarkProduct = activeOrderDetails?.products?.find(
      ({ code: { code } }) => code === PRODUCT_CODES.incStatesRegisterTrademark
    );

    if (registerTrademarkProduct) {
      return registerTrademarkProduct;
    }

    let registerTrademarkProductIndex = 0;
    const licensesUpsell = activeOrderUpsells.find((upsell) => {
      return upsell.products.find((product, idx) => {
        registerTrademarkProductIndex = idx;
        return product.code.code === PRODUCT_CODES.incStatesRegisterTrademark;
      });
    });

    if (licensesUpsell) {
      return licensesUpsell.products[registerTrademarkProductIndex];
    }

    return null;
  }
);

export const selectLicensesProduct = createSelector(
  [selectActiveOrderDetails, selectActiveOrderUpsells],
  (activeOrderDetails, activeOrderUpsells) => {
    const licensesProduct = activeOrderDetails?.products?.find(
      ({ code: { code } }) => code === PRODUCT_CODES.incStatesBusinessLicenses
    );

    if (licensesProduct) {
      return licensesProduct;
    }

    let licensesProductIndex = 0;
    const licensesUpsell = activeOrderUpsells.find((upsell) => {
      return upsell.products.find((product, idx) => {
        licensesProductIndex = idx;
        return product.code.code === PRODUCT_CODES.incStatesBusinessLicenses;
      });
    });

    if (licensesUpsell) {
      return licensesUpsell.products[licensesProductIndex];
    }

    return null;
  }
);

export const selectDissolutionProduct = createSelector(
  [selectActiveOrderDetails, selectActiveOrderUpsells],
  (activeOrderDetails, activeOrderUpsells) => {
    const dissolutionProduct = activeOrderDetails?.products?.find(
      ({ code: { code } }) => code === PRODUCT_CODES.incStatesDissolution
    );

    if (dissolutionProduct) {
      return dissolutionProduct;
    }

    let dissolutionProductIndex = 0;
    const dissolutionUpsell = activeOrderUpsells.find((upsell) => {
      return upsell.products.find((product, idx) => {
        dissolutionProductIndex = idx;
        return product.code.code === PRODUCT_CODES.incStatesDissolution;
      });
    });

    if (dissolutionUpsell) {
      return dissolutionUpsell.products[dissolutionProductIndex];
    }

    return null;
  }
);

export const selectOperatingAgreementProduct = createSelector(
  [selectActiveOrderDetails, selectActiveOrderUpsells],
  (activeOrderDetails, activeOrderUpsells) => {
    const productCode = PRODUCT_CODES.incStatesOperatingAgreement;

    const selectedProduct = activeOrderDetails?.products?.find(({ code: { code } }) => code === productCode);

    if (selectedProduct) {
      return selectedProduct;
    }

    let selectedProductIndex = 0;
    const selectedUpsell = activeOrderUpsells.find((upsell) => {
      return upsell.products.find((product, idx) => {
        selectedProductIndex = idx;
        return product.code.code === productCode;
      });
    });

    if (selectedUpsell) {
      return selectedUpsell.products[selectedProductIndex];
    }

    return null;
  }
);

export const selectBankingResolutionProduct = createSelector(
  [selectActiveOrderDetails, selectActiveOrderUpsells],
  (activeOrderDetails, activeOrderUpsells) => {
    const productCode = PRODUCT_CODES.incStatesBankingResolution;

    const selectedProduct = activeOrderDetails?.products?.find(({ code: { code } }) => code === productCode);

    if (selectedProduct) {
      return selectedProduct;
    }

    let selectedProductIndex = 0;
    const selectedUpsell = activeOrderUpsells.find((upsell) => {
      return upsell.products.find((product, idx) => {
        selectedProductIndex = idx;
        return product.code.code === productCode;
      });
    });

    if (selectedUpsell) {
      return selectedUpsell.products[selectedProductIndex];
    }

    return null;
  }
);

export const selectBoiProduct = createSelector(
  [selectActiveOrderDetails, selectActiveOrderUpsells],
  (activeOrderDetails, activeOrderUpsells) => {
    const productCode = PRODUCT_CODES.incStatesBoi;

    const selectedProduct = activeOrderDetails?.products?.find(({ code: { code } }) => code === productCode);

    if (
      selectedProduct &&
      (selectedProduct?.paymentStatus !== "Declined" || selectedProduct?.processingResult?.status !== "Lead")
    ) {
      return selectedProduct;
    }

    let selectedProductIndex = 0;
    const selectedUpsell = activeOrderUpsells.find((upsell) => {
      return upsell.products.find((product, idx) => {
        selectedProductIndex = idx;
        return product.code.code === productCode;
      });
    });

    if (
      selectedUpsell &&
      (selectedUpsell?.paymentStatus !== "Declined" || selectedUpsell?.processingResult?.status !== "Lead")
    ) {
      return selectedUpsell.products[selectedProductIndex];
    }

    return null;
  }
);

export const selectEINRegisterProduct = createSelector(
  [selectActiveOrderDetails, selectActiveOrderUpsells],
  (activeOrderDetails, activeOrderUpsells) =>
    findProduct(activeOrderDetails, activeOrderUpsells, [PRODUCT_CODES.incStatesEIN])
);

export const selectRAProduct = createSelector(
  [selectActiveOrderDetails, selectActiveOrderUpsells],
  (activeOrderDetails, activeOrderUpsells) =>
    findProduct(activeOrderDetails, activeOrderUpsells, [
      PRODUCT_CODES.incStatesResidentAgent,
      PRODUCT_CODES.incStatesRegisteredAgent,
    ])
);

export const selectACRProduct = createSelector(
  [selectActiveOrderDetails, selectActiveOrderUpsells],
  (activeOrderDetails, activeOrderUpsells) =>
    findProduct(activeOrderDetails, activeOrderUpsells, [PRODUCT_CODES.incStatesAnnualComplianceReport])
);

export const selectAggregatedProcessingStatus = createSelector([selectMainProduct], (mainProduct) => {
  const { processingResult } = mainProduct || {};
  return processingResult?.status ? getAggregatedProcessingStatus(processingResult.status) : null;
});

export const selectProcessingStatus = createSelector([selectMainProduct], (mainProduct) => {
  const { processingResult } = mainProduct || {};
  return processingResult?.status ? processingResult?.status : null;
});

export const selectProcessingError = createSelector([selectMainProduct], (mainProduct) => {
  const { processingResult } = mainProduct || {};
  return processingResult?.status ? processingResult?.processingError : null;
});

export const selectTrademarkProcessingStatus = createSelector([selectTrademarkProduct], (trademarkProduct) => {
  const { processingResult } = trademarkProduct || {};
  return processingResult?.status ? processingResult?.status : null;
});

export const selectCompanyName = createSelector([selectMainProduct], (mainProduct) => mainProduct?.companyName);
export const selectCompanyAltName = createSelector([selectMainProduct], (mainProduct) => mainProduct?.altCompanyName);
export const selectDBA = createSelector([selectMainProduct], (mainProduct) => mainProduct?.dba);
export const selectOrganizedState = createSelector([selectMainProduct], (mainProduct) => mainProduct?.organizedState);

export const selectRaAddress = createSelector(
  [selectRaAddresses, selectOrganizedState],
  (raAddresses, organizedState) => {
    return raAddresses?.find((address) => address.state === organizedState) || {};
  }
);

export const selectBoiWasDeclinedByCustomer = createSelector(
  [selectMainProduct],
  (mainProduct) => mainProduct?.boiWasDeclinedByCustomer || false
);

export const selectProductName = createSelector([selectMainProduct], (mainProduct) => mainProduct?.code?.customerName);

export const selectMembershipBlocked = createSelector([selectMainProduct], (mainProduct) =>
  Boolean(mainProduct?.membershipBlocked)
);

export const selectIncorporatedDate = createSelector(
  [selectMainProduct],
  (mainProduct) => mainProduct?.incorporatedDate
);

export const selectOwnerTitle = createSelector(
  [selectMainProduct],
  (mainProduct) => EIN_title_array[mainProduct?.owner?.title]
);

export const selectOwnerFullName = createSelector([selectMainProduct], (mainProduct) => {
  const { owner } = mainProduct || {};

  let result = [owner?.firstName, owner?.middleName, owner?.lastName, owner?.suffixName].reduce(
    (fullName, fragment, idx) => {
      if (idx === 0 && fragment) {
        fullName += fragment;
      }

      if (idx !== 0 && fragment) {
        fullName += ` ${fragment}`;
      }

      return fullName;
    },
    ""
  );

  if (owner?.title) {
    result += `, ${EIN_title_array[owner.title]}`;
  }

  return result;
});

export const selectPrimaryOfficerFullName = createSelector([selectMainProduct], (mainProduct) => {
  const { principalOfficer } = mainProduct || {};
  const { firstName, middleName, lastName, suffixName, title } = principalOfficer || {};

  let result = [firstName, middleName, lastName, suffixName].reduce((fullName, fragment, idx) => {
    if (idx === 0 && fragment) {
      fullName += fragment;
    }

    if (idx !== 0 && fragment) {
      fullName += ` ${fragment}`;
    }

    return fullName;
  }, "");

  if (title) {
    result += `, ${EIN_title_array[title]}`;
  }

  return result;
});

export const selectCorpAddressObj =
  createSelector([selectMainProduct], (mainProduct) => mainProduct?.corporateAddress) || null;
export const selectCorpAddress = createSelector([selectMainProduct], (mainProduct) => {
  const { corporateAddress } = mainProduct || {};
  return [corporateAddress?.address1, corporateAddress?.city, corporateAddress?.state, corporateAddress?.zip].reduce(
    (address, fragment, idx) => {
      if (idx !== 0 && idx !== 3 && fragment) {
        address += `, ${fragment}`;
      }

      if (idx === 0 && fragment) {
        address += fragment;
      }

      if (idx === 3 && fragment) {
        address += ` ${fragment}`;
      }

      return address;
    },
    ""
  );
});

export const selectNumberOfMembers = createSelector([selectMainProduct], (mainProduct) => mainProduct?.members?.length);
export const selectMembers = createSelector([selectMainProduct], (mainProduct) => mainProduct?.members);

export const selectMailFiles = createSelector([selectMainProduct], (mainProduct) => {
  if (!mainProduct || !mainProduct?.processingResult?.files) return [];
  const files = mainProduct?.processingResult?.files;
  const filteredFiles = Object.fromEntries(Object.entries(files)?.filter((item) => item[1]?.type === "TRADEMARK_MAIL"));
  const uploadedFileKeys = Object.keys(filteredFiles);
  return uploadedFileKeys
    .map((key) => {
      return { objKey: key, orderId: mainProduct.orderId, productId: mainProduct.id, ...filteredFiles[key] };
    })
    .sort((a, b) => moment(b.uploaded) - moment(a.uploaded));
});

export const selectSpecimenFiles = createSelector([selectTrademarkProduct], (regTmProduct) => {
  if (!regTmProduct || !regTmProduct?.processingResult?.files) return [];
  const files = regTmProduct?.processingResult?.files;
  const filteredFiles = Object.fromEntries(
    Object.entries(files)?.filter((item) => item[1]?.type === "TRADEMARK_SPECIMEN")
  );
  const uploadedFileKeys = Object.keys(filteredFiles);
  return uploadedFileKeys
    .map((key) => {
      return { objKey: key, orderId: regTmProduct.orderId, productId: regTmProduct.id, ...filteredFiles[key] };
    })
    .sort((a, b) => moment(b.uploaded) - moment(a.uploaded));
});

export const selectBills = createSelector([selectList], (list) => {
  return list?.map(({ uid, crmOrderId, productCodes, products }) => {
    const amount = products.reduce((acc, { price }) => acc + price, 0);
    const { paymentDate, paymentStatus } =
      products?.find(({ code: { category, main } }) => category === MAIN_CATEGORY && main) || products[0] || {};
    return {
      orderId: uid,
      crmOrderId,
      productCodes,
      paymentDate,
      paymentStatus,
      amount,
    };
  });
});

export const selectOrderFiles = createSelector(
  [selectActiveOrderDetails, selectFileTypes],
  (activeOrderDetails, fileTypes) => {
    return getProductsFiles(activeOrderDetails?.products, fileTypes);
  }
);

export const selectOrderUpsellsFiles = createSelector(
  [selectActiveOrderUpsells, selectFileTypes],
  (activeOrderUpsells, fileTypes) => {
    const products = activeOrderUpsells.reduce((acc, upsell) => {
      if (upsell?.products && Array.isArray(upsell?.products)) {
        return [...acc, ...upsell.products];
      }

      return acc;
    }, []);

    return getProductsFiles(products, fileTypes);
  }
);

export const selectTrademarkFiles = createSelector(
  [selectActiveOrderDetails, selectFileTypes],
  (activeOrderDetails, fileTypes) => {
    const trademarkProducts = activeOrderDetails?.products?.filter(({ code }) => {
      return [
        PRODUCT_CODES.incStatesRegisterTrademark,
        PRODUCT_CODES.incStatesTrademarkBasicSearch,
        PRODUCT_CODES.incStatesTrademarkMonitoring,
      ].includes(code?.code);
    });

    return getProductsFiles(trademarkProducts, fileTypes);
  }
);

export const selectTrademarkOwner = createSelector([selectActiveOrderDetails], (activeOrderDetails) => {
  const trademarkRegisterProduct = activeOrderDetails?.products?.find(({ code }) => {
    return PRODUCT_CODES.incStatesRegisterTrademark === code?.code;
  });

  const { address, ...restIndividual } = trademarkRegisterProduct?.individual || {};

  return {
    ...restIndividual,
    ...(address || {}),
  };
});

export const selectTrademarkSearchResults = createSelector([selectActiveOrderDetails], (activeOrderDetails) => {
  const trademarkBasicSearchProduct = activeOrderDetails?.products?.find(({ code }) => {
    return PRODUCT_CODES.incStatesTrademarkBasicSearch === code?.code;
  });

  return trademarkBasicSearchProduct?.processingResult?.federalSearchResults || [];
});

export const selectTrademarkMonitoringReports = createSelector([selectActiveOrderDetails], (activeOrderDetails) => {
  const trademarkMonitoringProduct = activeOrderDetails?.products?.find(({ code }) => {
    return PRODUCT_CODES.incStatesTrademarkMonitoring === code?.code;
  });

  return trademarkMonitoringProduct?.processingResult?.monitoringReports || {};
});

export const selectProcessingStatusesObj = createSelector([selectProcessingStatuses], (statuses) => {
  if (!statuses) return {};

  return statuses?.reduce((acc, status) => {
    return { ...acc, [status.statusCode]: status };
  }, {});
});

export const selectProcessingErrorsObj = createSelector([selectProcessingErrors], (errors) => {
  if (!errors) return {};

  return errors?.reduce((acc, error) => {
    return { ...acc, [error.code]: error };
  }, {});
});

export const selectStatesObj = createSelector([selectStates], (states) => {
  if (!states) return {};

  return states?.reduce((acc, state) => {
    return { ...acc, [state.code]: state.name };
  }, {});
});

export const selectTrademarkForm = createSelector([selectTrademarkProduct], (trademarkProduct) => {
  return parseTrademarkRegistrationProduct(trademarkProduct);
});

export const selectTrademarkDetailsData = createSelector([selectTrademarkForm], (trademarkForm) => {
  return createTrademarkDetailsData(trademarkForm);
});

export const selectAmendmentUpsells = createSelector([selectList], (list) => {
  if (!list?.length) return [];

  return list?.reduce((acc, order) => {
    const amendment = order?.products?.find(({ code: { code } }) => code?.includes("INC_States_Amendment"));
    if (amendment)
      return [
        ...acc,
        {
          status: amendment?.processingResult?.status,
          title: amendment?.code?.customerName,
          date: order?.orderDate,
          parentOrderUid: order?.parentOrderUid || null,
        },
      ];

    return acc;
  }, []);
});

export const selectActionRequiredProducts = createSelector(
  [selectActiveOrderDetails, selectActiveOrderUpsells, selectProcessingStatusesObj, selectMembershipBlocked],
  (order, upsells, processingStatusesObj, isMembershipBlocked) => {
    if (isMembershipBlocked) {
      return { resolved: [], unresolved: [] };
    }

    const resolvedArray = [];
    const unresolvedArray = [];

    const orders = [order, ...upsells];

    orders?.forEach((order) => {
      order?.products?.forEach((product) => {
        const logs = product?.processingResult?.logOfStatuses || [];

        if (logs?.length < 1) {
          const status = processingStatusesObj[product?.processingResult?.status];
          const actionRequired = status?.actionRequired[product?.code?.code];

          if (actionRequired?.actionRequired && actionRequired?.customerActionDescription) {
            unresolvedArray.push({ timestamp: null, status, product });
          }
        } else {
          logs.forEach((log, idx) => {
            const status = processingStatusesObj[log?.status];
            const actionRequired = status?.actionRequired ? status?.actionRequired[product?.code?.code] : null;

            if (actionRequired?.actionRequired && actionRequired?.customerActionDescription) {
              const actionItem = {
                ...log,
                type: "ACTION",
                title: actionRequired?.actionRequired,
                actionRequired,
                product,
                order,
              };

              if (idx === logs.length - 1) {
                if (actionItem.status === "Error") {
                  const processingError = actionItem?.product?.processingResult?.processingError;
                  const productCode = actionItem?.product?.code?.code;

                  if (
                    processingError === "CardDeclined" &&
                    !actionItem?.product?.code?.subscription &&
                    !CARD_DECLINED_ALLOWED_PRODUCTS.includes(productCode)
                  ) {
                    return;
                  }

                  if (processingError) actionItem.error = processingError;

                  const errorObject = ERROR_ACTIONS_OBJ[processingError];

                  if (
                    errorObject &&
                    (errorObject.productCodes.length === 0 || errorObject.productCodes.includes(productCode))
                  ) {
                    actionItem.actionRequired = errorObject.action;
                    unresolvedArray.push(actionItem);
                  }
                } else {
                  unresolvedArray.push(actionItem);
                }
              } else {
                if (logs[idx + 1]?.timestamp) {
                  actionItem.timestamp = logs[idx + 1]?.timestamp;
                }
                resolvedArray.push(actionItem);
              }
            }
          });
        }
      });
    });

    return {
      resolved: resolvedArray.sort((a, b) => moment(b.timestamp) - moment(a.timestamp)),
      unresolved: unresolvedArray.sort((a, b) => moment(b.timestamp) - moment(a.timestamp)),
    };
  }
);

export const selectCustomActionRequired = createSelector(
  [selectActiveOrderDetails, selectBoiWasDeclinedByCustomer, selectBoiProduct, selectMembershipBlocked],
  (activeOrderDetails, boiWasDeclinedByCustomer, boiProduct, isMembershipBlocked) => {
    if (!activeOrderDetails) return [];

    const customActions = [
      {
        id: "1",
        type: CUSTOM_ACTION_CODES.boiOffer,
        notificationTitle: "Beneficial Ownership Information (BOI)",
        notificationDescription: "Filing Requirement",
        actionRequired: {
          actionRequired: "Beneficial Ownership Information (BOI) Filing Requirement",
          customerActionDescription:
            "The United States Department of Treasury has introduced a significant regulatory update known as the Corporate Transparency Act (CTA), aimed at enhancing the transparency of corporate entities in the United States. As part of this initiative, most new and existing businesses, including Limited Liability Companies (LLCs), are required to file a Beneficial Ownership Information (BOI) report with the Financial Crimes Enforcement Network (FinCEN), a bureau of the US Department of Treasury.\nFor businesses formed or registered before January 1, 2024, it is essential to note that the BOI report must be filed by January 1, 2025. We encourage you to mark this date on your calendar to ensure timely compliance with this regulatory requirement.",
        },
        title: "Beneficial Ownership Information (BOI) Filing Requirement",
        quickActionTitle: "Beneficial Ownership Information (BOI) Filing Requirement",
        description: `The United States Department of Treasury has introduced a significant regulatory update known as the Corporate Transparency Act (CTA), aimed at enhancing the transparency of corporate entities in the United States. As part of this initiative, most new and existing businesses, including Limited Liability Companies (LLCs), are required to file a Beneficial Ownership Information (BOI) report with the Financial Crimes Enforcement Network (FinCEN), a bureau of the US Department of Treasury.\nFor businesses formed or registered before January 1, 2024, it is essential to note that the BOI report must be filed by January 1, 2025. We encourage you to mark this date on your calendar to ensure timely compliance with this regulatory requirement.`,
        shouldBeRendered: !boiProduct && !boiWasDeclinedByCustomer && !isMembershipBlocked,
      },
      {
        id: "2",
        type: CUSTOM_ACTION_CODES.blockedRA,
        notificationTitle: "Registered Agent Invoice Due",
        notificationDescription: "See Details",
        actionRequired: {
          actionRequired: "Registered Agent Invoice Due",
          customerActionDescription:
            "Our records show that your account has an outstanding balance of $199.00 for your Registered Agent.",
        },
        title: "Registered Agent Invoice Due",
        quickActionTitle: "Registered Agent Invoice Due",
        description: `Our records show that your account has an outstanding balance of $199.00 for your Registered Agent.`,
        shouldBeRendered: isMembershipBlocked,
        redirectUrl: (config?.publicUrl || "") + "/blocked/registered-agent",
      },
    ];

    return customActions.filter((action) => action.shouldBeRendered);
  }
);

export const selectActionRequiredProductsCount = createSelector(
  [selectActionRequiredProducts, selectCustomActionRequired, selectEINRegisterProduct],
  (list, customList, EINProduct) => {
    let length = list?.unresolved?.length || 0;
    length += customList?.length || 0;

    const hasEINData = EINProduct?.owner?.ssn && EINProduct?.startDate && EINProduct?.closingMonth;
    const isEinStatusRequireAction = EINProduct?.processingResult?.status === "AwaitingBusinessEINInformation";

    if (isEinStatusRequireAction && EINProduct && hasEINData) {
      length -= 1;
    }

    return length;
  }
);

export const selectBusinessLicenses = createSelector([selectLicensesProduct], (product) => {
  const licenses = product?.processingResult?.licenses || [];

  return licenses;
});

export const selectActiveOrderSubscriptions = createSelector(
  [selectActiveOrderDetails, selectActiveOrderUpsells, selectSubscriptions],
  (order, upsells, subscriptions) => {
    const products = [];

    order?.products?.forEach((item) => {
      products.push(item);
    });

    upsells?.forEach((upsell) => {
      upsell?.products?.forEach((item) => {
        products.push(item);
      });
    });

    const resultArray = subscriptions.reduce((acc, subscription) => {
      const product = products.find((item) => {
        return item.id === subscription.productId;
      });

      if (!product) return acc;

      return [...acc, { ...subscription, product }];
    }, []);

    return resultArray;
  }
);

export const selectActiveOrderActiveSubscriptions = createSelector(
  [selectActiveOrderSubscriptions],
  (subscriptions) => {
    return subscriptions?.filter(({ status }) => status === "ACTIVE");
  }
);

export const selectPrices = (state) => state.orders.prices.data;
export const selectPricesError = (state) => state.orders.prices.error;
export const selectPricesLoading = (state) => state.orders.prices.loading;

export const selectPriceMapByProductCode = (productCode) =>
  createSelector([selectPrices], (prices) => {
    return prices
      .filter(({ code: { code } }) => code === productCode)
      .reduce((res, { option, price }) => {
        res[option] = price;
        return res;
      }, {});
  });

export const selectPriceMapByProcessingCode = (processingCode) =>
  createSelector([selectPrices], (prices) => {
    return prices
      .filter(({ option }) => option === processingCode)
      .reduce((res, { code: { code }, price }) => {
        res[code] = price;
        return res;
      }, {});
  });

export const selectFees = (state) => state.orders.fees.data;
export const selectFeesError = (state) => state.orders.fees.error;
export const selectFeesLoading = (state) => state.orders.fees.loading;

export const selectFeeMapByProductCode = (productCode) =>
  createSelector([selectFees], (fees) => {
    return fees
      .filter(({ code: { code } }) => code === productCode)
      .reduce((res, { option, price }) => {
        res[option] = price;
        return res;
      }, {});
  });

export const selectFeeMapByProcessingCode = (processingCode) =>
  createSelector([selectFees], (fees) => {
    return fees
      .filter(({ option }) => option === processingCode)
      .reduce((res, { code: { code }, price }) => {
        res[code] = price;
        return res;
      }, {});
  });
