import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { AMENDMENT_PRODUCTS } from "_constants/products";
import { amendments_options_array } from "_constants/variables";
import { handleError, hasProductBeenInStatus, phoneCleaner, replaceEmptyStringsWithNull } from "_helpers";
import { OrdersService } from "_services";
import {
  selectActiveOrderId,
  selectActiveOrderProductCodes,
  selectAmendmentUpsells,
  selectMainProduct,
} from "_store/orders/selector";
import { getOrder } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Icon from "components/atomic/atoms/Icon";
import Indicator from "components/atomic/atoms/Indicator";
import Select from "components/atomic/atoms/Select";
import { SpinnerBlock } from "components/atomic/atoms/Spinner";
import Table from "components/atomic/molecules/Table";
import AmendmentBusinessAddressForm from "components/atomic/organisms/forms/amendments/AmendmentBusinessAddressForm";
import AmendmentBusinessNameForm from "components/atomic/organisms/forms/amendments/AmendmentBusinessNameForm";
import AmendmentChangeRegisterAgentForm from "components/atomic/organisms/forms/amendments/AmendmentChangeRegisterAgentForm";
import AmendmentManagerChangeForm from "components/atomic/organisms/forms/amendments/AmendmentManagerChangeForm";
import AmendmentOtherForm from "components/atomic/organisms/forms/amendments/AmendmentOtherForm";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const columns = [
  {
    dataIndex: "status",
    title: "#",
    width: "4%",
    minWidth: "20px",
    render: (row) => <Indicator status={row?.status} />,
  },
  {
    dataIndex: "title",
    title: "Title",
    width: "80%",
    // minWidth: "200px",
    render: (row) => row?.title || "—",
  },
  {
    dataIndex: "date",
    title: "Date",
    width: "16%",
    minWidth: "100px",
    render: (row) =>
      row?.date ? (
        <div>
          <div>{moment(row?.date).format("MM/DD/YY")}</div>
          <div>{moment(row?.date).format("HH:mm")}</div>
        </div>
      ) : (
        "—"
      ),
  },
];

const amendmentsWithForms = [
  AMENDMENT_PRODUCTS.incStatesAmendmentBusinessAddress,
  AMENDMENT_PRODUCTS.incStatesAmendmentBusinessName,
  AMENDMENT_PRODUCTS.incStatesAmendmentAllOtherAmendments,
  AMENDMENT_PRODUCTS.incStatesAmendmentManager,
  AMENDMENT_PRODUCTS.incStatesAmendmentRegisteredAgent,
];

function renderForm(code, formRef, handlers = {}) {
  const formProps = {
    handlers,
  };

  switch (code) {
    case AMENDMENT_PRODUCTS.incStatesAmendmentBusinessAddress:
      return <AmendmentBusinessAddressForm ref={formRef} {...formProps} />;

    case AMENDMENT_PRODUCTS.incStatesAmendmentBusinessName:
      return <AmendmentBusinessNameForm ref={formRef} {...formProps} />;

    case AMENDMENT_PRODUCTS.incStatesAmendmentAllOtherAmendments:
      return <AmendmentOtherForm ref={formRef} {...formProps} />;

    case AMENDMENT_PRODUCTS.incStatesAmendmentManager:
      return <AmendmentManagerChangeForm ref={formRef} {...formProps} />;

    case AMENDMENT_PRODUCTS.incStatesAmendmentRegisteredAgent:
      return <AmendmentChangeRegisterAgentForm ref={formRef} {...formProps} />;

    default:
      return null;
  }
}

const STEPS = {
  unavailable: "step-unavailable",
  start: "step-start",
  loading: "step-loading",
  filling: "step-filling",
  success: "step-success",
  error: "step-error",
};

// eslint-disable-next-line no-unused-vars
const RequestAmendmentModal = ({ onCancel }) => {
  const dispatch = useDispatch();

  const orderId = useSelector(selectActiveOrderId);
  const customerId = useSelector(selectCustomerId);
  const productCodes = useSelector(selectActiveOrderProductCodes);
  const mainProduct = useSelector(selectMainProduct);
  const amendments = useSelector(selectAmendmentUpsells);
  const formRef = useRef(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(STEPS.loading);

  const availableAmendmentOptions = useMemo(() =>
    amendments_options_array.filter(
      (option) => {
        if (option?.deprecated) {
          return false;
        }

        if (option.requiredProduct) {
          return productCodes.includes(option.requiredProduct);
        }

        return true;
      },
      [productCodes]
    )
  );

  const rows = amendments?.filter(({ parentOrderUid }) => parentOrderUid === orderId);

  async function createAmendmentUpsale(orderId, body) {
    try {
      const request = await OrdersService.createDelayedUpsell({ orderId, body });

      if (request?.status === 200) {
        setStep(STEPS.success);
      } else {
        setStep(STEPS.error);
        setError({ message: "Unknown Error" });
      }
    } catch (response) {
      setStep(STEPS.error);
      setError(handleError(response));
    }
  }

  function handleBack() {
    setStep(STEPS.start);
  }
  function handleSubmit(e) {
    e.preventDefault();
    formRef.current.submit();
  }

  function handleSend(data = {}) {
    const newData = replaceEmptyStringsWithNull(data);

    if (data?.phone && typeof data.phone === "string" && data.phone.length > 0) {
      newData.phone = phoneCleaner(data.phone);
    }

    const body = {
      order: { processingOption: "UPSELL", campaignId: 28 },
      products: [{ code: { code: selectedOption?.value }, ...newData }],
    };

    createAmendmentUpsale(orderId, body);
    setStep(STEPS.loading);
  }

  function handleCloseModal({ update = false }) {
    setError(null);
    setStep(STEPS.start);
    onCancel();
    // update && dispatch(requestUpdate("REFRESH"));
    update && dispatch(getOrder({ orderId, customerId, refreshOrders: true }));
  }

  useEffect(() => {
    if (mainProduct) {
      const wasSubmitted = hasProductBeenInStatus(mainProduct, ["SubmittedToState", "Completed"]);

      if (!wasSubmitted) {
        setStep(STEPS.unavailable);
      } else {
        setStep(STEPS.start);
      }
    } else {
      setStep(STEPS.unavailable);
    }
  }, [mainProduct]);

  switch (step) {
    case STEPS.start:
      return (
        <Modal centered show onHide={() => handleCloseModal({ update: false })} size="md">
          <StyledHeader closeButton>Request Amendment</StyledHeader>
          <StyledBody>
            <Row>Please select the option that corresponds to the necessary amendments.</Row>
            <Select
              secondary
              options={availableAmendmentOptions}
              value={selectedOption}
              onChange={(option) => {
                setSelectedOption(option);
              }}
            />
            <Row>
              Please note that amendment requests incur a $99.00 processing fee (inclusive of State fees). After
              clicking Process below, your card on file will be billed for this amount. An agent will follow up with you
              directly to complete your request.
            </Row>

            <Buttons>
              <Button variant="secondary" onClick={() => handleCloseModal({ update: false })}>
                Cancel
              </Button>
              <Button
                variant="success"
                disabled={!selectedOption}
                onClick={() => {
                  if (amendmentsWithForms.includes(selectedOption?.value)) {
                    setStep(STEPS.filling);
                  } else {
                    setStep(STEPS.loading);
                    handleSend();
                  }
                }}
              >
                Process
              </Button>
            </Buttons>
          </StyledBody>
          {Boolean(rows?.length) && (
            <Modal.Footer>
              <Row bold>
                Amendments History:
                <Table hiddenHeader columns={columns} rows={rows} maxHeight="200px" />
              </Row>
            </Modal.Footer>
          )}
        </Modal>
      );

    case STEPS.filling:
      return (
        <Modal centered show backdrop="static" onHide={() => handleCloseModal({ update: false })} size="lg">
          <StyledHeader closeButton>Request Amendment</StyledHeader>
          <StyledBody>
            <Row>Please, fill in the necessary information.</Row>
            <Row>{renderForm(selectedOption?.value, formRef, { onSubmit: handleSend })}</Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="secondary" type="button" onClick={handleBack}>
              Back
            </Button>
            <Button variant="success" type="button" onClick={handleSubmit}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.loading:
      return (
        <Modal centered show onHide={() => {}} size="md">
          <StyledHeader>Request Amendment</StyledHeader>
          <StyledBody>
            <SpinnerBlock message="Sending Request" />
          </StyledBody>
        </Modal>
      );

    case STEPS.success:
      return (
        <Modal centered show onHide={() => handleCloseModal({ update: true })} size="md">
          <StyledHeader>Request Amendment</StyledHeader>
          <StyledBody>
            <Row>
              <Icon inline icon="misc:success-check" size="32px" color="#32D583" />
              &nbsp;We have received your amendment request, and will be filing shortly.
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.error:
      return (
        <Modal centered show onHide={() => handleCloseModal({ update: true })} size="md">
          <StyledHeader>Request Amendment</StyledHeader>
          <StyledBody>
            <Row>
              <Icon inline icon="error:alert" size="24px" />
              &nbsp;Please, try again later or contact with our agents.
            </Row>
            <Row>{error?.message ? `Error: ${error?.message}` : ""}</Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.unavailable:
      return (
        <Modal centered show onHide={() => handleCloseModal({ update: false })} size="md">
          <StyledHeader>Request Amendment</StyledHeader>
          <StyledBody>
            <Row>
              <Icon inline icon="misc:info" size="24px" color="#007bff" />
              &nbsp;Your order is still being processed, which means changes can be made free of charge. Please contact
              customer service to request any changes.
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCloseModal({ update: false })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
};

RequestAmendmentModal.propTypes = {
  onCancel: PropTypes.func,
};

export default RequestAmendmentModal;
